import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { useLocation } from "@reach/router";

type Props = {
  to?: string;
  onClick?: () => void;
};
type StyleProps = {
  active: boolean;
};

export const Button = styled.button<StyleProps>`
  display: block;
  text-align: center;
  width: 100%;
  /* min-height: 4.3rem; */
  padding: 1.2rem;
  margin: auto;
  font-family: inherit;
  font-size: var(--default-font-size);
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  background-color: var(--color-secondary);
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  &:hover {
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
    transform: translate(0, -5px);
  }
  :focus {
    outline: none;
  }
`;

const LinkButton: React.FC<Props> = ({ to, onClick, children }) => {
  const location = useLocation();

  if (to) {
    onClick = () => {
      navigate(to);
    };
  }

  return (
    <Button onClick={onClick} active={location.pathname === to}>
      {children}
    </Button>
  );
};

export default LinkButton;
