import React, { useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import {
  Container,
  Warning,
  Description,
  Title,
  StyledButtonContainer,
} from "./NotFound.styles";
import LinkButton from "../button/LinkButton.component";
import { isBrowser } from "../../lib/browser";
import { Loading } from "../loading";

type Props = RouteComponentProps;

const NotFound: React.FC<Props> = () => {
  useEffect(() => {
    navigate("/", { replace: true });
  }, []);

  return isBrowser() ? (
    <Container>
      <Warning>Oh no!</Warning>
      <Description>
        The page you’re looking for doesn’t exist or is undergoing maintenance
      </Description>
      <Title>404</Title>
      <StyledButtonContainer>
        <LinkButton to="/">Take Me Home</LinkButton>
      </StyledButtonContainer>
    </Container>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default NotFound;
