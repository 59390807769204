import styled from "styled-components";
import { device } from "../../styles/device";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3rem;
  padding-bottom: 20rem;
  height: 100vh;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    140deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(25, 25, 112, 1) 55%,
    rgba(0, 212, 255, 1) 100%
  );
`;

export const Title = styled.h1`
  color: var(--color-white);
  font-weight: 500;
  font-size: 24rem;

  @media ${device.mobile} {
    font-size: 16rem;
  }
`;

export const Warning = styled.h2`
  color: var(--color-white);
  font-weight: 500;
  font-size: 8rem;

  @media ${device.mobile} {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  color: var(--color-white);
  font-weight: 300;
  font-size: 3rem;

  @media ${device.mobile} {
    font-size: 2rem;
  }
`;

export const StyledButtonContainer = styled.div`
  width: 60rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: auto;
  text-align: center;

  @media ${device.mobile} {
    width: 90%;
    max-width: 35rem;
  }
`;
