// MEDIA QUERY MANAGER
/*
0 - 600px     Phone
600 - 900px   Tablet Potrait
900 - 1200px  Tablet Landscape
1200 - 1800px normal styles
1800px+       Big Desktop
*/

export const device = {
  mobile: `(min-width: 18.75em)`,
  tabPort: `(min-width: 37.5em)`,
  tabLand: `(min-width: 56.25em)`,
  desktop: `(min-width: 75em)`,
  bigDesktop: `(min-width: 112.5em)`,
};
