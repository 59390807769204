import React from "react";
import NotFound from "../components/not-found/NotFound.component";
import { SEO } from "../components/seo/seo";
import Layout from "../components/layout/layout";

const NotFoundPage = (): React.ReactNode => {
  return (
    <Layout>
      <SEO title="404" />
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;
