import React from "react";
import GlobalStyle from "../../styles/global-styles";

const Layout: React.FC = ({ children }) => {
  return (
    <div>
      <GlobalStyle />
      {children}
    </div>
  );
};

export default Layout;
